<template>
  <v-dialog v-model="dialog" persistent max-width="550px">
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t("financial.clearDuePayment") }}
        </span>
        <v-spacer></v-spacer>
        <v-icon @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    hide-details="auto"
                    v-model="ends_at"
                    :label="$t('financial.endDate')"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="green darken-4"
                  v-model="ends_at"
                  @input="menu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12">
              <v-text-field
                type="number"
                v-model="amount"
                :label="$t('financial.amount')"
                :placeholder="$t('financial.amount')"
                @input="$v.amount.$touch()"
                @blur="$v.amount.$touch()"
                :error-messages="amountErrors"
                hide-details="auto"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea
                outlined
                v-model="description"
                :label="$t('financial.comments')"
                :placeholder="$t('financial.comments')"
                @input="$v.description.$touch()"
                @blur="$v.description.$touch()"
                :error-messages="descriptionErrors"
                hide-details="auto"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" dark dense @click="close">
          {{ $t("member.cancel") }}</v-btn
        >
        <v-btn
          color="primary"
          :loading="updating"
          :disabled="($v.$invalid && $v.$anyDirty) || updating"
          @click="update"
        >
          {{ $t("member.save") }}
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import { showSnackbar } from "../../eventbus/action.js";
import moment from "moment";
export default {
  name: "ClearDuePayment",
  props: {
    dialogStatus: {
      required: true,
      type: Boolean,
      default: false,
    },
    selectedUser: {
      required: false,
      type: Object,
      default: () => {
        return { user_detail: {} };
      },
    },
  },
  computed: {
    amountErrors() {
      const errors = [];
      if (!this.$v.amount.$dirty) return errors;
      !this.$v.amount.required && errors.push(this.$t("financial.amountError"));
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      !this.$v.description.required &&
        errors.push(this.$t("financial.descriptionError"));
      return errors;
    },
  },

  data() {
    return {
      ends_at: moment().format("yyyy-MM-DD"),
      menu: false,
      updating: false,
      amount: null,
      description: "",
      dialog: this.dialogStatus,
    };
  },

  validations: {
    amount: { required },
    description: { required },
  },

  methods: {
    ...mapActions({
      clearDue: "financialDashboard/clearDuePayment",
    }),

    close() {
      this.dialog = false;
      this.$emit("close", false);
    },

    async update() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.updating = true;
          await this.clearDue({
            id: this.selectedUser.id,
            amount: this.amount,
            ends_at: this.ends_at,
            description: this.description,
          });
          this.$emit("refresh");
          await this.close();
        } catch ({ message }) {
          const errorMessage =
            typeof message === "object"
              ? Object.values(message)[0][0]
              : message;
          showSnackbar(errorMessage);
        } finally {
          this.updating = false;
        }
      }
    },
  },
};
</script>
